<template>
  <div class="">
    <div class="d-flex mb-7 logo-container align-center justify-center">
      <div class="img-div"><img class="img" :src="logo" alt="" /></div>
      <div class="text-div">
        <img class="img" :src="logo_text" alt="" />
      </div>
      <br />
    </div>
    <div class="d-flex justify-center">
      <LoginForm v-if="getStatus == 'LOGIN'"></LoginForm>
      <PasswordResetConfirm
        v-if="getStatus === 'FORGET_PASSWORD'"
      ></PasswordResetConfirm>
      <ResetPassword v-if="getStatus === 'RESET_PASSWORD'"></ResetPassword>
    </div>
  </div>
</template>

<script>
import LoginForm from "@/components/Authentication/LoginForm.vue";
import ResetPassword from "@/components/Authentication/ResetPassword.vue";
import PasswordResetConfirm from "@/components/Authentication/PasswordResetConfirm.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    LoginForm,
    ResetPassword,
    PasswordResetConfirm,
  },
  name: "Login",
  computed: {
    ...mapGetters({
      getStatus: "Util/status",
    }),
  },
  data() {
    return {
      logo: require("@/assets/ohtel_logo.png"),
      logo_text: require("@/assets/ohtel_text.png"),
    };
  },
  mounted() {},
};
</script>

<style scoped>
.container {
  background: #e5e5e5;
  min-height: 100vh;
}
.logo-container {
  padding-top: 100px;
}
.img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.text {
  font-size: 22px;
}
.small-text {
  font-size: 10px !important;
}
.img-div {
  width: 70px;
  height: 70px;
}
.text-div {
  margin: 10px;
  width: 170px;
  height: 70px;
}
</style>