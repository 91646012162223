<template>
  <div>
    <v-app-bar app style="background-color: white" elevation="1">
      <span class="hidden-sm-and-up">
        <v-app-bar-nav-icon @click="sidebar = !sidebar"
          ><v-icon>mdi-menu</v-icon>
        </v-app-bar-nav-icon>
      </span>
      <v-app-bar-title class="ma-2 fix2">
        <div class="d-flex" style="cursor: pointer; height: 70px">
          <div class="img-div"><img class="img" :src="logo" alt="logo" /></div>
          <div class="text-div">
            <img class="img" :src="logo_text" alt="logo" />
          </div>
        </div>
      </v-app-bar-title>
      <div style="max-width: 900px">
        <v-slide-group
          show-arrows
          class="mt-3 text-center"
          next-icon="mdi-arrow-right-circle"
          prev-icon="mdi-arrow-left-circle"
        >
          <v-slide-item
            v-for="item in menuItems"
            :key="item.title"
            class="mx-3 mt-3 d-flex justify-center align-center"
            v-slot="{ active, toggle }"
          >
            <router-link
              class="navlink text-center"
              :input-value="active"
              depressed
              rounded
              v-bind:name="item.title"
              v-if="perm(item.perm)"
              :to="item.path"
              @click="toggle"
            >
              {{ item.title }}
            </router-link>
          </v-slide-item>
        </v-slide-group>
      </div>

      <v-text-field
        prepend-inner-icon="mdi-magnify"
        background-color="#F2F2F2"
        height="35"
        color="#4D449B"
        outlined
        full-width
        class="mt-7 search__field"
        placeholder="Search "
        style="border-radius: 8px; display: none !important"
        filled
        dense
      ></v-text-field>
      <v-menu
        bottom
        class="d-flex justify-right"
        style="margin: 0px 10px 0px 300px; float: right"
        min-width="220px"
        rounded
        offset-y
      >
        <template v-slot:activator="{ on }">
          <div style="padding: 2px; display: flex; margin-bottom: 4px">
            <v-avatar
              color="#4d449b"
              size="40"
              v-on="on"
              style="cursor: pointer"
            >
              <span class="white--text">Ak</span>
            </v-avatar>
            <i
              class="fas fa-angle-down fa-sm"
              v-on="on"
              style="margin: 10px 1px; color: #111; cursor: pointer"
            >
            </i>
          </div>
        </template>
        <v-card>
          <v-list-item-content class="justify-center">
            <div class="pl-2 pb-5 elevation-1">
              <h4 class="pb-3">Akash Verma</h4>
              <strong>987484848484</strong>
            </div>
          </v-list-item-content>
          <v-list-item-content class="justify-center">
            <div class="mx-auto text-center">
              <button
                class="elevation-1 purple lighten-4 px-4 py-2 logout-btn"
                style="border-radius: 5px"
                @click="logout"
              >
                Logout
              </button>
            </div>
          </v-list-item-content>
        </v-card>
      </v-menu>
    </v-app-bar>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "Nav",
  data() {
    return {
      logo: require("@/assets/ohtel_logo.png"),
      logo_text: require("@/assets/ohtel_text.png"),
      menuItems: [
        {
          title: "Home",
          path: "/home",
          perm: "has_home_acess",
        },
        {
          title: "Users",
          path: "/users",
          perm: "has_user_access",
        },
        {
          title: "Category",
          path: "/categories",
          perm: null,
        },
        {
          title: "Post",
          path: "/posts",
          perm: "has_posts_access",
        },
        {
          title: "Subscription plan",
          path: "/subcription-plan",
          perm: "has_subscription_plan_access",
        },

        // {
        //   title: "User Subscription ",
        //   path: "/user-subscriptions",
        //   perm: "has_user_subcription_plan_access",
        // },
        {
          title: "Sub Category",
          path: "/sub-category",
          perm: "has_master_sub_category_access",
        },
        {
          title: "Transactions",
          path: "/transactions",
          perm: "has_master_sub_category_access",
        },
        {
          title: "Notification",
          path: "/notification",
          perm: "has_master_sub_category_access",
        },
        // {
        //   title: "Feedbacks",
        //   path: "/feedbacks",
        //   perm: "has_master_sub_category_access",
        // },
      ],
    };
  },
  methods: {
    ...mapActions("Common", ["clearState"]),
    logout() {
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
      this.clearState();

      this.$router.push("/login");
    },
    perm(p) {
      return true;
    },
  },
  mounted() {},
};
</script>

<style scoped>
h3 {
  margin: 40px 0 0;
}
.router-link-exact-active {
  border-bottom: 6px solid #4d449b;
  padding-top: 10px;
  padding-bottom: 5px;
  border-radius: 7px;
  /* box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.25); */
  /* background-color: #fffddc; */
  color: #4d449b !important;
}
.input-field {
  border: 1px solid grey;

  background-color: white;
  height: 40px;
  font-size: 18px;
  padding: 5px;
  text-align: left;
}
.logo {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.logo-div {
  width: 100px;
  height: 100px;
}
ul {
  list-style-type: none;
  padding-left: 10px;
}
li {
  float: right;
  margin: 0 10px;
  padding: 2px;
}
a {
  color: #4d449b;
}
.navlink {
  min-width: 90px;
  cursor: pointer;
  color: black;
  text-decoration: none;
  outline: none;
  font-size: 15px;
  text-align: center;

  /* white-space: pre-wrap; */
}
.img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.img-div {
  margin: 5px 2px 5px 5px;
  width: 70px;
  height: 60px;
}
.text-div {
  margin: 5px 10px 5px 5px;
  width: 70px;
  height: 70px;
}
.v-application .mt-3{
  margin-left: 100px;
}

@media only screen and (max-width: 970px) {
  .fix {
    margin-left: 50px;
  }
  .fix2 {
    margin: 10px;
  }
}
</style>