const urls = {
  //BASE URL
  BASE_URL: process.env.VUE_APP_API_URL,

  //Auth
  LOGIN: '/admin/admin_login/',
  FORGET_PASSWORD: '/admin/forget_password',
  PASSWORD_RESET: '/admin/change_password/',

  //Home Count
  DASHBOARD_COUNT: 'admin/admin_homepage_api/',

  //Category
  CATEGORY_LIST: '/admin/category_list/',
  REGION_LIST: '/regions/list',
  ADMIN_CATEGORY_LIST: '/admin/category/list/',
  //SubCategory
  ADD_SUB_CATEGORY: '/admin/category/create_sub_category/',
  UPDATE_SUB_CATEGORY: 'admin/category/rud_sub_category/',
  ADD_APPLICANT_SUB_CATEGORY: '/admin/category/create_applicant_sub_category/',
  ADD_APPLICANT_ROLE: '/admin/category/create_applicant_role/',
  APPLICANT_ROLE_LIST: '/admin/applicant-role/list/',
  // APPLICANT_SUB_CATEGORY_LIST
  //User
  USER_LIST: '/admin/get_all_user_list/',

  SUBCATEGORY_LIST: '/admin/sub_category_list',
  APPLICANT_SUB_CATEGORY_LIST: '/admin/category/applicant_sub_category_list/',
  SUBSCRIPTION_LIST: '/admin/subscription/get_subscription_list/',
  TRANSACTIONS_LIST: 'user/admin/transaction/list/',
};

export default urls;
