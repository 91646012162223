import {Authconfig} from '@/app-js/Util';
import messages from '@/app-js/messages';
import urls from '@/app-js/urls';
import axios from 'axios';
const state = {
  subscription_list: [],
  total_page: 1,
  addingSubscriptionPlan: false,
  subscriptionLoader: false,
  isLoadingSubscriptionList: false,
  subscriptionDetail: {},
};
const getters = {
  subscription_list: state => {
    return state.subscription_list;
  },
  page_length: state => {
    return state.total_page;
  },
  isaddingSubscriptionPlan: state => {
    return state.addingSubscriptionPlan;
  },
  isFetchingSubscription: state => {
    return state.subscriptionLoader;
  },
  isFetchingSubscriptionList: state => {
    return state.isLoadingSubscriptionList;
  },
};

const actions = {
  async get_subscription_list ({commit, dispatch}, page) {
    commit ('SET_LOADING_SUBSCRIPTION_LIST');
    const successHandler = res => {
      commit ('SET_SUBSCRIPTION_LIST', res.data.results);
      if (res.data.count === 10) {
        commit ('SET_TOTAL_PAGE_LENGTH', parseInt (res.data.count / 10));
      } else {
        commit ('SET_TOTAL_PAGE_LENGTH', parseInt (res.data.count / 10 + 1));
      }
      commit ('SET_LOADING_SUBSCRIPTION_LIST');
    };
    const errroHandler = err => {
      if (err.code === 'ERR_NETWORK') {
        dispatch (
          'Snackbar/showSnack',
          {
            text: `Record Not Found, Please refresh or try again later`,
            color: 'pink lighten-1',
            timeout: 3000,
          },
          {root: true}
        );
      } else {
        dispatch (
          'Snackbar/showSnack',
          {text: `${err.response.data.detail}`, color: 'error', timeout: 3000},
          {root: true}
        );
      }
      commit ('SET_LOADING_SUBSCRIPTION_LIST');
    };
    try {
      const res = await axios.get (
        `${urls.SUBSCRIPTION_LIST}?page=${page}`,
        Authconfig
      );
      if (res.status === 200) {
        successHandler (res);
      }
    } catch (error) {
      errroHandler (error);
    }
  },

  async getSubscriptionDetail ({commit, dispatch}, id) {
    const successHandler = res => {
      commit ('SET_SUBSCRIPTION_LOADER');
      commit ('SET_SUBSCRIPTION_DETAIL', res.data.result);
    };
    const errroHandler = err => {
      if (err.code === 'ERR_NETWORK') {
        dispatch (
          'Snackbar/showSnack',
          {
            text: `Record Not Found, Please refresh or try again later`,
            color: 'pink lighten-1',
            timeout: 3000,
          },
          {root: true}
        );
      } else {
        dispatch (
          'Snackbar/showSnack',
          {text: `${err.response.data.detail}`, color: 'error', timeout: 3000},
          {root: true}
        );
      }
      commit ('SET_SUBSCRIPTION_LOADER');
    };
    try {
      const res = await axios.get (
        `admin/subscription/detail/${id}`,
        Authconfig
      );
      if (res.status === 200) {
        successHandler (res);
      }
    } catch (error) {
      errroHandler (error);
    }
  },
  async updateSubscriptionDetail ({commit, dispatch}, id) {
    const successHandler = res => {
      commit ('SET_SUBSCRIPTION_LOADER');
    };
    const errroHandler = err => {
      if (err.code === 'ERR_NETWORK') {
        dispatch (
          'Snackbar/showSnack',
          {
            text: `Record Not Found, Please refresh or try again later`,
            color: 'pink lighten-1',
            timeout: 3000,
          },
          {root: true}
        );
      } else {
        dispatch (
          'Snackbar/showSnack',
          {text: `${err.response.data.detail}`, color: 'error', timeout: 3000},
          {root: true}
        );
      }
      commit ('SET_SUBSCRIPTION_LOADER');
    };
    try {
      const res = await axios.get (
        `admin/subscription/update/${id}`,
        Authconfig
      );
      if (res.status === 200) {
        successHandler (res);
      }
    } catch (error) {
      errroHandler (error);
    }
  },

  async createSubscription ({commit, dispatch}, payload) {
    state.addingSubscriptionPlan = true;
    const successHandler = res => {
      dispatch (
        'Snackbar/showSnack',
        {
          text: `Subscription Plan Added Succesfully`,
          color: 'success',
          timeout: 3000,
        },
        {root: true}
      );
      state.addingSubscriptionPlan = false;
    };
    const errorHandler = err => {
      if (err.code === 'ERR_NETWORK') {
        dispatch (
          'Snackbar/showSnack',
          {
            text: `Record Not Found, Please refresh or try again later`,
            color: 'pink lighten-1',
            timeout: 3000,
          },
          {root: true}
        );
      } else {
        dispatch (
          'Snackbar/showSnack',
          {text: `${err.response.data.detail}`, color: 'error', timeout: 3000},
          {root: true}
        );
      }
      state.addingSubscriptionPlan = false;
    };
    try {
      const res = await axios.post (
        'admin/subscription/create_subscription/',
        payload,
        Authconfig
      );
      if (res.status === 201) {
        successHandler (res);
        dispatch ('get_subscription_list');
      }
    } catch (error) {
      errorHandler (error);
    }
    state.addingSubscriptionPlan = false;
  },
};
const mutations = {
  SET_SUBSCRIPTION_LIST: (state, payload) => {
    state.subscription_list = payload;
  },
  SET_TOTAL_PAGE_LENGTH: (state, payload) => {
    state.total_page = payload;
  },
  SET_SUBSCRIPTION_LOADER: state => {
    state.subscriptionLoader = !state.subscriptionLoader;
  },
  SET_SUBSCRIPTION_DETAIL: (state, payload) => {
    state.subscriptionDetail = payload;
  },
  SET_LOADING_SUBSCRIPTION_LIST: state => {
    state.isLoadingSubscriptionList = !state.isLoadingSubscriptionList;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
