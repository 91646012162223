<template>
  <div class="card">
    <h3 class="my-10 text-center">Enter Mobile Number</h3>
    <form
      class="form"
      @submit.prevent="sendOTP($store.state.Authentication.phone)"
    >
      <v-text-field
        outlined
        v-model="$store.state.Authentication.phone"
        prepend-inner-icon="mdi-account"
        label="phone"
      ></v-text-field>
      <v-btn class="signin-btn" height="50" color="#4D449B" type="submit"
        >Send OTP</v-btn
      >
    </form>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "PasswordResetConfirm",
  data() {
    return {};
  },
  methods: {
    ...mapActions("Authentication", ["sendOTP"]),
  },
  mounted() {},
};
</script>

<style scoped>
.card {
  margin: 10px;
  border-radius: 5px;
  background: #fff;
  padding: 50px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  width: 450px;
  max-height: 420px;
}
.signin-btn {
  width: 100%;

  color: #fff !important;
  letter-spacing: 0px;
  font-size: 22px !important;
  text-transform: capitalize;
}
.cursor {
  cursor: pointer;
  font-size: 18px;
}
</style>