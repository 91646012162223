<template>
  <div class="card">
    <h3 class="text-center">Reset Password</h3>
    <form
      class="form"
      @submit.prevent="
        resetPassword({ phone: getPhone, new_password, confirm_password, otp })
      "
    >
      <v-text-field
        outlined
        v-model="new_password"
        prepend-inner-icon="mdi-key"
        label="New password"
        type="password"
      ></v-text-field>
      <v-text-field
        outlined
        v-model="confirm_password"
        prepend-inner-icon="mdi-key"
        label="Confirm Password"
        type="password"
      ></v-text-field>
      <v-text-field
        outlined
        v-model="otp"
        prepend-inner-icon="mdi-phone"
        label="OTP"
        type="text"
      ></v-text-field>
      <v-btn class="signin-btn" height="50" color="#4D449B" type="submit"
        >Reset Password</v-btn
      >
    </form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "ResetPassword",
  data() {
    return {
      new_password: null,
      otp: null,
      confirm_password: null,
    };
  },
  computed: {
    ...mapGetters({
      getPhone: "Authentication/getPhone",
    }),
  },
  methods: {
    ...mapActions("Authentication", ["resetPassword"]),
  },
  mounted() {},
};
</script>

<style scoped>
.card {
  margin: 10px;
  border-radius: 5px;
  background: #fff;
  padding: 30px 50px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  width: 450px;
  min-height: 300px;
}
.signin-btn {
  width: 100%;

  color: #fff !important;
  letter-spacing: 0px;
  font-size: 22px !important;
  text-transform: capitalize;
}
.cursor {
  cursor: pointer;
  font-size: 18px;
}
</style>