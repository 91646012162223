import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routes';

Vue.use (VueRouter);

const router = new VueRouter ({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

const DEFAULT_TITLE = 'Some Default Title';
router.afterEach ((to, from, next) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  Vue.nextTick (() => {
    document.title = to.meta.title || DEFAULT_TITLE;
  });
  if (!localStorage.getItem ('access_token') && !to.meta.guest) {
    router.push('/login')
    next ({
      path: '/login',
      // Redirect to original path if specified
    });
  } else {
    // next ();
  }
});
export default router;
