import {Authconfig, MultiPartConfig} from '@/app-js/Util';
import urls from '@/app-js/urls';
import axios from 'axios';
const state = {
  categoryId: 1,
  categories: [],
  subCategories: [],
  applicantSubCategories: [],
  totalSubCategoryPages: 1,
  adminCategories: [],
  addingSubCategory: false,
  loadingTable: false,
  regionList: [],
};
const getters = {
  categories: state => {
    return state.categories;
  },
  getRegions: state => {
    return state.regions;
  },
  subCategoriesList: state => {
    return state.subCategories;
  },
  totalPages: state => {
    return state.totalSubCategoryPages;
  },
  getCategoryId: state => {
    return state.categoryId;
  },
  getMainCategories: state => {
    return state.categories;
  },
  getApplicantSubCategoriesList: state => {
    return state.applicantSubCategories;
  },
  isAddingSubCategory: state => {
    return state.addingSubCategory;
  },
  isTableLoading: state => {
    return state.loadingTable;
  },
};

const actions = {
  //region list
  async getRegions({commit, dispatch}) {
    const successHandler = res => {
      commit ('SET_REGIONS', res.data.results);
    };
    const errorHandler = err => {
      if (err.code === 'ERR_NETWORK') {
        dispatch (
          'Snackbar/showSnack',
          {
            text: `Record Not Found, Please refresh or try again later`,
            color: 'pink lighten-1',
            timeout: 3000,
          },
          {root: true}
        );
      } else {
        dispatch (
          'Snackbar/showSnack',
          {text: `${err.response.data.detail}`, color: 'error', timeout: 3000},
          {root: true}
        );
      }
    };
    try {
      const res = await axios.get (`${urls.REGION_LIST}`, Authconfig);
      if (res.status === 200) {
        successHandler (res);
      }
    } catch (error) {
      errroHandler (error);
    }
  },
  // Get list of all master subcategories
  async getCategories({commit, dispatch}) {
    const successHandler = res => {
      commit ('SET_CATEGORIES', res.data.result);
    };
    const errroHandler = err => {
      if (err.code === 'ERR_NETWORK') {
        dispatch (
          'Snackbar/showSnack',
          {
            text: `Record Not Found, Please refresh or try again later`,
            color: 'pink lighten-1',
            timeout: 3000,
          },
          {root: true}
        );
      } else {
        dispatch (
          'Snackbar/showSnack',
          {text: `${err.response.data.detail}`, color: 'error', timeout: 3000},
          {root: true}
        );
      }
    };
    try {
      const res = await axios.get (`${urls.CATEGORY_LIST}`, Authconfig);
      if (res.status === 200) {
        successHandler (res);
      }
    } catch (error) {
      errroHandler (error);
    }
  },
  //Get list of master categories to be used in adding a subcategory
  async getAdminCategories({commit, dispatch}) {
    const succesHandler = res => {
      commit ('SET_ADMIN_CATEGORIES', res.data.results);
    };
    const errorHandler = err => {
      if (err.code === 'ERR_NETWORK') {
        dispatch (
          'Snackbar/showSnack',
          {
            text: `Record Not Found, Please refresh or try again later`,
            color: 'pink lighten-1',
            timeout: 3000,
          },
          {root: true}
        );
      } else {
        dispatch (
          'Snackbar/showSnack',
          {text: `${err.response.data.detail}`, color: 'error', timeout: 3000},
          {root: true}
        );
      }
    };
    try {
      const res = await axios.get (urls.ADMIN_CATEGORY_LIST, Authconfig);
      if (res.status === 200) {
        succesHandler (res);
      }
    } catch (error) {
      errorHandler (error);
    }
  },
  //Get list of subcategories by passing master category id
  async getSubCategories ({commit, dispatch, state}, page = 1) {
    state.loadingTable = true;
    console.log (page, 'page');
    const successHandler = res => {
      commit ('SET_SUB_CATEGORIES', res.data.results);
      commit (
        'SET_TOTAL_SUB_CATEGORY_PAGES',
        parseInt (res.data.count / 100 + 1)
      );
      state.loadingTable = false;
    };
    const errroHandler = err => {
      if (err.code === 'ERR_NETWORK') {
        dispatch (
          'Snackbar/showSnack',
          {
            text: `Record Not Found, Please refresh or try again later`,
            color: 'pink lighten-1',
            timeout: 3000,
          },
          {root: true}
        );
      } else {
        dispatch (
          'Snackbar/showSnack',
          {text: `${err.response.data.detail}`, color: 'error', timeout: 3000},
          {root: true}
        );
      }
      state.loadingTable = false;
    };
    try {
      let URL = '';
      if (state.categoryId === 8) {
        URL = `${urls.APPLICANT_SUB_CATEGORY_LIST}?category_id=${state.categoryId}&page=${page}`;
      } else if (state.categoryId === 9) {
        URL = `${urls.APPLICANT_ROLE_LIST}?page${page}`;
      } else {
        URL = `${urls.SUBCATEGORY_LIST}?category_id=${state.categoryId}&page=${page}`;
      }
      console.log (URL, 'url');
      const res = await axios.get (`${URL}`, Authconfig);
      // if (res.status === 200) {
      successHandler (res);
      // }
    } catch (error) {
      errroHandler (error);
    }
  },
  async getApplicantSubcategories ({commit}, page = 1) {
    state.loadingTable = true;
    const successHandler = res => {
      commit ('SET_APPLICANT_SUB_CATEGORIES', res.data.results);
      state.loadingTable = false;
    };
    const errorHandler = err => {
      if (err.code === 'ERR_NETWORK') {
        dispatch (
          'Snackbar/showSnack',
          {
            text: `Record Not Found, Please refresh or try again later`,
            color: 'pink lighten-1',
            timeout: 3000,
          },
          {root: true}
        );
      } else {
        dispatch (
          'Snackbar/showSnack',
          {text: `${err.response.data.detail}`, color: 'error', timeout: 3000},
          {root: true}
        );
      }
      state.loadingTable = false;
    };
    try {
      let URL = '';
      URL = `${urls.APPLICANT_SUB_CATEGORY_LIST}?category_id=8&page=${page}`;
      const res = await axios.get (URL, Authconfig);
      if (res.status === 200) {
        successHandler (res);
      }
    } catch (error) {
      errorHandler (error);
    }
  },
  //Set Category id globally, Maintains the stat of current tab in Subcategoy section
  setCategoryId ({commit}, categoryId) {
    commit ('SET_CATEGORY_ID', categoryId);
  },
  // Add subcategory by passing master category id
  async addSubCategory ({commit, dispatch, state}, payload) {
    state.addingSubCategory = true;
    const successHandler = res => {
      dispatch (
        'Snackbar/showSnack',
        {
          text: `Sub Category Added Succesfully`,
          color: 'success',
          timeout: 3000,
        },
        {root: true}
      );
      state.addingSubCategory = false;
    };
    const errorHandler = err => {
      if (err.code === 'ERR_NETWORK') {
        dispatch (
          'Snackbar/showSnack',
          {
            text: `Record Not Found, Please refresh or try again later`,
            color: 'pink lighten-1',
            timeout: 3000,
          },
          {root: true}
        );
      } else {
        dispatch (
          'Snackbar/showSnack',
          {text: `${err.response.data.detail}`, color: 'error', timeout: 3000},
          {root: true}
        );
      }
      state.addingSubCategory = false;
    };
    try {
      const res = await axios.post (
        urls.ADD_SUB_CATEGORY + `${state.categoryId}/`,
        payload,
        MultiPartConfig
      );
      if (res.status === 201) {
        successHandler (res);
        this.getSubCategories ();
      }
    } catch (error) {
      errorHandler (error);
    }
  },
  async addApplicantSubCategory ({commit, dispatch, state}, payload) {
    state.addingSubCategory = true;
    const succesHandler = res => {
      dispatch (
        'Snackbar/showSnack',
        {
          text: `Sub Category Added Succesfully`,
          color: 'success',
          timeout: 3000,
        },
        {root: true}
      );
      state.addingSubCategory = false;
    };
    const errorHandler = err => {
      if (err.code === 'ERR_NETWORK') {
        dispatch (
          'Snackbar/showSnack',
          {
            text: `Record Not Found, Please refresh or try again later`,
            color: 'pink lighten-1',
            timeout: 3000,
          },
          {root: true}
        );
      } else {
        dispatch (
          'Snackbar/showSnack',
          {text: `${err.response.detail}`, color: 'error', timeout: 3000},
          {root: true}
        );
      }
      state.addingSubCategory = false;
    };
    try {
      const res = await axios.post (
        urls.ADD_APPLICANT_SUB_CATEGORY,
        payload,
        MultiPartConfig
      );
      succesHandler (res);
    } catch (error) {
      errorHandler (error);
    }
  },
  async addApplicantRole ({commit, dispatch, state}, payload) {
    state.addingSubCategory = true;
    const succesHandler = res => {
      dispatch (
        'Snackbar/showSnack',
        {
          text: `Applicant Role Added Succesfully`,
          color: 'success',
          timeout: 3000,
        },
        {root: true}
      );
      state.addingSubCategory = false;
    };
    const errorHandler = err => {
      if (err.code === 'ERR_NETWORK') {
        dispatch (
          'Snackbar/showSnack',
          {
            text: `Record Not Found, Please refresh or try again later`,
            color: 'pink lighten-1',
            timeout: 3000,
          },
          {root: true}
        );
      } else {
        dispatch (
          'Snackbar/showSnack',
          {text: `${err.response.data.detail}`, color: 'error', timeout: 3000},
          {root: true}
        );
      }
      state.addingSubCategory = false;
    };
    try {
      const res = await axios.post (
        urls.ADD_APPLICANT_ROLE,
        payload,
        MultiPartConfig
      );
      succesHandler (res);
    } catch (error) {
      errorHandler (error);
    }
  },
  async updateSubCategory({ commit, dispatch }, payload) {
    const successHandler = (res) => {
      dispatch("Snackbar/showSnack", { text: `${res.data.detail}`, color: "success", timeout: 2000 }, { root: true });
      // Optionally dispatch any other actions or update the state upon successful update
    };
    const errorHandler = (err) => {
      console.log(err);
      // Handle error if update fails
      // Optionally dispatch error snackbar or perform other error handling
    };
    try {
      const res = await axios.put(`${urls.UPDATE_SUB_CATEGORY}/${payload.id}/`, payload, MultiPartConfig);
      if (res.status === 200) {
        successHandler(res);
        // Optionally dispatch any other actions or update the state upon successful update
      }
    } catch (error) {
      errorHandler(error);
    }
  },
};
const mutations = {
  SET_REGIONS: (state, payload) => {
    state.regionList = payload;
  },
  SET_CATEGORIES: (state, payload) => {
    state.categories = payload;
  },
  SET_SUB_CATEGORIES: (state, payload) => {
    state.subCategories = payload;
  },
  SET_TOTAL_SUB_CATEGORY_PAGES: (state, payload) => {
    state.totalSubCategoryPages = payload;
  },
  SET_CATEGORY_ID: (state, payload) => {
    state.categoryId = payload;
  },
  SET_ADMIN_CATEGORIES: (state, payload) => {
    state.adminCategories = payload;
  },
  SET_APPLICANT_SUB_CATEGORIES: (state, payload) => {
    state.applicantSubCategories = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
