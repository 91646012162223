import LoginView from '../views/LoginView.vue';

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: LoginView,
    meta: {title: 'Login', guest: true},
  },
  {
    path: '/home',
    name: 'Home', // This route has a unique name 'Home'
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Home.vue'),
    meta: { title: 'Home', guest: false },
  },
  {
    path: '/',
    name: 'Root', // Unique name for this route
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Home.vue'),
    meta: { title: 'Home', guest: false },
  },
  {
    path: '/categories',
    name: 'Category',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import (/* webpackChunkName: "about" */ '../views/Category.vue'),
    meta: {title: 'Home', guest: false},
  },
  {
    path: '/users',
    name: 'Users',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import (/* webpackChunkName: "about" */ '../views/users/Users.vue'),
    meta: {title: 'Users', guest: false},
  },
  {
    path: '/enquiry',
    name: 'Enquiry',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import (/* webpackChunkName: "about" */ '../views/enquiry/Enquiry.vue'),
    meta: {title: 'Home', guest: false},
  },
  {
    path: '/posts',
    name: 'Posts',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import (/* webpackChunkName: "about" */ '../views/post/Post.vue'),
    meta: {title: 'Posts', guest: false},
  },
  {
    path: '/posts/detail/:id',
    name: 'PostsDetails',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import (/* webpackChunkName: "about" */ '../views/post/PostDetails.vue'),
    meta: {title: 'Post Detail', guest: false},
  },
  {
    path: '/subcription-plan',
    name: 'SubcriptionPlan',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import (
        /* webpackChunkName: "about" */ '../views/subscription_plans/SubscriptionPlan.vue'
      ),
    meta: {title: 'Subscription Plan', guest: false},
  },
  {
    path: '/user-subscriptions',
    name: 'UserSubcriptions',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import (
        /* webpackChunkName: "about" */ '../views/user_subscription/UserSubscription.vue'
      ),
    meta: {title: 'Home', guest: false},
  },
  {
    path: '/sub-category',
    name: 'SubCategory',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import (
        /* webpackChunkName: "about" */ '../views/SubCategory/SubCategory.vue'
      ),
    meta: {title: 'Sub Category', guest: false},
  },
  {
    path: '/feedbacks',
    name: 'Feedbacks',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import (/* webpackChunkName: "about" */ '../views/Feedbacks.vue'),
    meta: {title: 'Sub Category', guest: false},
  },
  {
    path: '/user/detail/:id',
    name: 'UserDetail',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import (/* webpackChunkName: "about" */ '../views/users/UserDetail.vue'),
    meta: {title: 'User Details', guest: false},
  },
  {
    path: '/transactions',
    name: 'Transactions',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import (/* webpackChunkName: "about" */ '../views/Transactions.vue'),
    meta: {title: 'Transactions', guest: false},
  },
  {
    path: '/notification',
    name: 'Notification',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import (/* webpackChunkName: "about" */ '../views/Notification.vue'),
    meta: {title: 'Notification', guest: false},
  },
  {
    path: '/*',
    name: 'PageNotFound',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import (/* webpackChunkName: "about" */ '../views/PageNotFound.vue'),
    meta: {title: '404', guest: true},
  },
];

export default routes;
