<template>
  <div>
    <div class="card">
      <h3 class="my-7 text-center">Login</h3>
      <form class="form" @submit.prevent="login({ phone, password })">
        <v-text-field
          outlined
          v-model="phone"
          prepend-inner-icon="mdi-account"
          label="phone"
        ></v-text-field>
        <v-text-field
          outlined
          v-model="password"
          prepend-inner-icon="mdi-key"
          label="Password"
          type="password"
        ></v-text-field>
        <v-btn
          :loading="formLoading"
          class="signin-btn"
          height="50"
          color="#4D449B"
          type="submit"
          :disabled="!phone || !password || formLoading"
          >Login</v-btn
        >
      </form>
      <div class="my-4 d-flex justify-end cursor">
        <!-- <p @click="changeStatus('FORGET_PASSWORD')">Forgot Password ?</p> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "LoginForm",
  data() {
    return {
      phone: null,
      password: null,
      loader: false
    };
  },
  computed: {
    ...mapGetters({
      getProducts: "Common/access",
      formLoading: "Authentication/getFormLoading"
    }),
  },
  methods: {
    ...mapActions("Authentication", ["login"]),
    ...mapActions("Common", ["changeStatus"]),
  },
  mounted() {},
};
</script>

<style scoped>
.card {
  margin: 10px;
  border-radius: 5px;
  background: #fff;
  padding: 10px 50px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  width: 450px;
  min-height: 300px;
}
.signin-btn {
  width: 100%;

  color: #fff !important;
  letter-spacing: 0px;
  font-size: 22px !important;
  text-align: center;
  text-transform: capitalize;
}
.cursor {
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
}
</style>