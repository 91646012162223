import {Authconfig} from '@/app-js/Util';
import messages from '@/app-js/messages';
import urls from '@/app-js/urls';
import axios from 'axios';
axios.interceptors.request.use (
  config => {
    const token = localStorage.getItem ('access_token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => Promise.reject (error)
);
const state = {
  access_token: localStorage.getItem ('access_token'),
  refresh_token: localStorage.getItem ('refresh_token'),
  STATUS: 'LOGIN',
  counts: [],
};
const getters = {
  access: state => {
    return state.access_token;
  },
  refresh: state => {
    return state.refresh_token;
  },
  status: state => {
    return state.STATUS;
  },
  counts: state => {
    return state.counts;
  },
};

const actions = {
  changeStatus: ({commit}, payload) => {
    commit ('SET_STATUS', payload);
  },
  clearState: ({commit}) => {
    commit ('CLEAR_STATE');
  },
  async getCount({commit, dispatch}) {
    const successHandler = res => {
      commit ('SET_COUNT', res.data.result);
    };
    const errroHandler = err => {
      if (err.code === 'ERR_NETWORK') {
        dispatch (
          'Snackbar/showSnack',
          {
            text: `Record Not Found, Please refresh or try again later`,
            color: 'pink lighten-1',
            timeout: 3000,
          },
          {root: true}
        );
      } else {
        dispatch (
          'Snackbar/showSnack',
          {
            text: `${err.response.data.detail}`,
            color: 'pink lighten-1',
            timeout: 3000,
          },
          {root: true}
        );
      }
    };
    try {
      const res = await axios.get (`${urls.DASHBOARD_COUNT}`, Authconfig);
      if (res.status === 200) {
        successHandler (res);
      } else {
        console.log (err, 'err');
      }
    } catch (error) {
      errroHandler (error);
    }
  },
};

const mutations = {
  SET_TOKENS: (state, payload) => {
    state.access_token = payload;
  },
  SET_STATUS: (state, payload) => {
    state.STATUS = payload;
  },
  CLEAR_STATE: state => {
    state.access_token = null;
    state.refresh_token = null;
    state.STATUS = 'LOGIN';
  },
  SET_COUNT: (state, payload) => {
    state.counts = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
