import Vue from 'vue';
import Vuex from 'vuex';
import User from './modules/User';
import Snackbar from './modules/Snackbar.js';
import Util from './modules/Util.js';
import Posts from './modules/PostStore.js';
import SubCategory from './modules/SubCategory.js';
import Subscription from './modules/Subscription.js';
import Authentication from './modules/Authentication.js';
Vue.use (Vuex);

export default new Vuex.Store ({
  modules: {
    User,
    Posts,
    Util,
    Snackbar,
    SubCategory,
    Subscription,
    Authentication,
  },
});
