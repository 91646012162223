<template>
  <v-app>
    <Nav v-if="access"></Nav>
    <v-main>
      <div name="snackbars">
        <v-snackbar
          v-model="show"
          :color="color"
          :timeout="timeout"
          top
          right
          elevation="24"
        >
          {{ text }}
          <template v-slot:action="{ attrs }">
            <v-btn dark text v-bind="attrs" @click="show = false">
              Close
            </v-btn>
          </template>
        </v-snackbar>
      </div>
      <transition name="route" mode="out-in">
        <router-view v-slot="{ Component }">
          <component :is="Component" />
        </router-view>
      </transition>
    </v-main>
  </v-app>
</template>

<script>
import Nav from "@/components/Utils/Nav.vue";
import { mapGetters } from "vuex";
export default {
  name: "App",
  components: {
    Nav,
  },
  computed: {
    ...mapGetters("Util", ["access", "refresh"]),
  },

  data: () => ({
    show: false,
    color: "",
    text: "",
    timeout: -1,
    //
  }),
  created() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === "Snackbar/SHOW_MESSAGE") {
        this.text = state.Snackbar.text;
        this.color = state.Snackbar.color;
        this.timeout = state.Snackbar.timeout;
        this.show = true;
      }
    });
  },
  mounted() {
  },
};
</script>

<style >
.route-enter-from {
  opacity: 0;
  transform: translateX(100px);
}
.route-enter-active {
  transition: all 0.4s ease-in-out;
}
.route-leave-to {
  opacity: 0;
  transform: translateX(-100px);
}
.route-leave-active {
  transition: all 0.3s ease-in;
}
.fade-enter-active {
  transition: opacity 0.25s ease;
  transition-delay: 0.25s;
}
</style>