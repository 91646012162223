import {Authconfig, MultiPartConfig} from '@/app-js/Util';
import urls from '@/app-js/urls';
import axios from 'axios';
axios.defaults.baseURL = urls.BASE_URL;

const state = {
  active_count: 0,
  expired_count: 0,
};

const getters = {
  active: state => {
    return state.active_count;
  },
  expired: state => {
    return state.expired_count;
  },
};

const actions = {
  async getCount({commit, dispatch}) {
    const successHandler = res => {
      commit ('SET_COUNTS', res.data);
    };
    const errorHandler = err => {
      if (err.code === 'ERR_NETWORK') {
        dispatch (
          'Snackbar/showSnack',
          {
            text: `Record Not Found, Please refresh or try again later`,
            color: 'pink lighten-1',
            timeout: 3000,
          },
          {root: true}
        );
      } else {
        dispatch (
          'Snackbar/showSnack',
          {text: `${err.response.data.detail}`, color: 'error', timeout: 3000},
          {root: true}
        );
      }
    };
    try {
      const res = await axios.get (`ads/count/`, Authconfig);
      if (res.status === 200) {
        successHandler (res);
      }
    } catch (error) {
      errorHandler (error);
    }
  },
};

const mutations = {
  SET_COUNTS: (state, payload) => {
      console.log(payload)
    state.active_count = payload.active_count;
    state.expired_count = payload.expired_count;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
