import axios from 'axios';
import urls from '@/app-js/urls';

import {Authconfig} from '@/app-js/Util';

const state = {
  users: [],
  total_pages: 1,
  transactionList: [],
  transactionsTotalPages: 1,
};
const getters = {
  getUsers: state => {
    return state.users;
  },
  getTotalPages: state => {
    return state.total_pages;
  },
};
const actions = {
  async getUserLists ({commit, dispatch}, page) {
    const successHandler = res => {
      commit ('SET_USERS', res.data.result);
    };
    const errroHandler = err => {
      // Show error snakbar on failure of OTP sent
      if (err.code === 'ERR_NETWORK') {
        dispatch (
          'Snackbar/showSnack',
          {
            text: `Record Not Found, Please refresh or try again later`,
            color: 'pink lighten-1',
            timeout: 3000,
          },
          {root: true}
        );
      } else {
        dispatch (
          'snackbar/showSnack',
          {
            text: `${err.response.data.detail}`,
            color: 'pink lighten-1',
            timeout: 3000,
          },
          {root: true}
        );
      }
    };
    try {
      const res = await axios.get (
        `${urls.USER_LIST}?page=${page}`,
        Authconfig
      );
      if (res.status === 200) {
        successHandler (res);
      }
    } catch (error) {
      errroHandler (error);
    }
  },
  async fetchTransctions ({commit, dispatch}, page) {
    const successHandler = res => {
      commit ('SET_TRANSACTIONS_LIST', res.data.result);
    };
    const errroHandler = err => {
      // Show error snakbar on failure of OTP sent
      if (err.code === 'ERR_NETWORK') {
        dispatch (
          'Snackbar/showSnack',
          {
            text: `Record Not Found, Please refresh or try again later`,
            color: 'pink lighten-1',
            timeout: 3000,
          },
          {root: true}
        );
      } else {
        dispatch (
          'snackbar/showSnack',
          {
            text: `${err.response.data.detail}`,
            color: 'pink lighten-1',
            timeout: 3000,
          },
          {root: true}
        );
      }
    };
    try {
      const res = await axios.get (
        `${urls.TRANSACTIONS_LIST}?page=${page}`,
        Authconfig
      );
      if (res.status === 200) {
        successHandler (res);
      }
    } catch (error) {
      errroHandler (error);
    }
  },
};
const mutations = {
  SET_USERS: (state, payload) => {
    state.users = payload.data;
    state.total_pages = payload.total_pages;
  },
  SET_TRANSACTIONS_LIST: (state, payload) => {
    state.transactionList = payload.data;
    state.transactionsTotalPages = Math.ceil (payload.count / 10);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
