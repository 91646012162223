import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import * as Sentry from '@sentry/vue';
import {BrowserTracing} from '@sentry/tracing';
Vue.config.productionTip = false;
// Sentry.init ({
//   Vue,
//   dsn: 'https://11d131ecbff34b3e9c3e880228731a30@o1410695.ingest.sentry.io/6754567',
//   integrations: [
//     new BrowserTracing ({
//       routingInstrumentation: Sentry.vueRouterInstrumentation (router),
//       tracingOrigins: ['localhost', 'my-site-url.com', /^\//],
//     }),
//   ],
//   debug: process.env.VUE_APP_ENVIRONMENT !== 'production',
//   tracesSampleRate: process.env.VUE_APP_ENVIRONMENT === 'production' ? 0.2 : 1,
//   tracingOptions: {
//     trackComponents: true,
//   },
//   // Vue specific
//   logErrors: process.env.VUE_APP_ENVIRONMENT === 'production' ? false : false,
//   attachProps: true,
//   attachStacktrace: true,
//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// });

new Vue ({
  router,
  store,
  vuetify,
  render: h => h (App),
}).$mount ('#app');
