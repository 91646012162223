const loginConfig = {
  headers: {
    'Content-Type': 'application/json',
  },
};
const Authconfig = {
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem ('access_token')}`,
  },
};
const MultiPartConfig = {
  headers: {
    'Content-Type': 'multipart/form-data',
    Authorization: `Bearer ${localStorage.getItem ('access_token')}`,
  },
};

export {loginConfig, Authconfig, MultiPartConfig};
