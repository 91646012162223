import axios from 'axios';
import urls from '@/app-js/urls';
import messages from '@/app-js/messages';
import router from '@/router';
import {loginConfig} from '@/app-js/Util';

axios.defaults.baseURL = urls.BASE_URL;

const state = {
  phone: null,
  formLoader: false,
};

const getters = {
  getPhone: state => {
    return state.phone;
  },
  getFormLoading: state => {
    return state.formLoader;
  },
};

const actions = {
  async login ({commit, dispatch}, payload) {
    commit ('SET_FORM_LOADING');
    const successHandler = res => {
      localStorage.setItem ('access_token', res.data.result);

      dispatch (
        'Snackbar/showSnack',
        {
          text: `${messages.LOGIN_SUCCESSFULL}`,
          color: 'success',
          timeout: 5000,
        },
        {root: true}
      );
      commit ('Util/SET_TOKENS', res.data.result, {root: true});

      router.push ('/home');
    };
    const errorHandler = err => {
      if (err.code === 'ERR_NETWORK') {
        dispatch (
          'Snackbar/showSnack',
          {
            text: `Record Not Found, Please refresh or try again later`,
            color: 'pink lighten-1',
            timeout: 3000,
          },
          {root: true}
        );
      } else {
        dispatch (
          'Snackbar/showSnack',
          {text: `${err.response.data.detail}`, color: 'error', timeout: 3000},
          {root: true}
        );
      }
    };
    try {
      const res = await axios.post (`${urls.LOGIN}`, payload, loginConfig);

      if (res.status === 200) {
        successHandler (res);
        commit ('SET_FORM_LOADING');
      }
    } catch (error) {
      errorHandler (error);
      commit ('SET_FORM_LOADING');
    }
  },
  async sendOTP ({commit, dispatch}, payload) {
    const successHandler = res => {
      //change global auth status to reset password
      dispatch ('Util/changeStatus', 'RESET_PASSWORD', {root: true});
      // show Snackbar for successfull OTP sent
      dispatch (
        'Snackbar/showSnack',
        {text: `${res.data.result}`, color: 'success', timeout: 3000},
        {root: true}
      );
    };
    const errroHandler = err => {
      // Show error snakbar on failure of OTP sent
      if (err.code === 'ERR_NETWORK') {
        dispatch (
          'Snackbar/showSnack',
          {
            text: `Record Not Found, Please refresh or try again later`,
            color: 'pink lighten-1',
            timeout: 3000,
          },
          {root: true}
        );
      } else {
        dispatch (
          'Snackbar/showSnack',
          {text: `${err.response.data.detail}`, color: 'error', timeout: 3000},
          {root: true}
        );
      }
    };
    try {
      const res = await axios.get (
        `${urls.FORGET_PASSWORD}` + `?phone=${payload}`,
        loginConfig
      );
      if (res.status === 200) {
        successHandler (res);
      }
    } catch (error) {
      errroHandler (error);
    }
  },
  async resetPassword ({commit, dispatch}, payload) {
    const successHandler = res => {
      router.push ('/');
      dispatch ('Util/changeStatus', 'LOGIN', {root: true});
      dispatch (
        'Snackbar/showSnack',
        {text: `${res.data.result}`, color: 'success', timeout: 3000},
        {root: true}
      );
    };
    const errroHandler = err => {
      if (err.code === 'ERR_NETWORK') {
        dispatch (
          'Snackbar/showSnack',
          {
            text: `Record Not Found, Please refresh or try again later`,
            color: 'pink lighten-1',
            timeout: 3000,
          },
          {root: true}
        );
      } else {
        dispatch (
          'Snackbar/showSnack',
          {text: `${err.response.data.detail}`, color: 'error', timeout: 3000},
          {root: true}
        );
      }
    };
    try {
      const res = await axios.post (
        `${urls.PASSWORD_RESET}`,
        payload,
        loginConfig
      );
      if (res.status === 200) {
        successHandler (res);
      }
    } catch (error) {
      errroHandler (error);
    }
  },
};

const mutations = {
  SET_FORM_LOADING: state => {
    state.formLoader = !state.formLoader;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
